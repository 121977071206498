body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

pre, code, dd, dd input, textarea {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 12px;
}

dt { float: left }
dd { margin-left: 100px }
dd:after { content: ""; display: block; clear: both }
dt, dd { line-height: 1.25rem; vertical-align: baseline }
dd input, textarea { width: 100%; border: 0 }

body { width: 500px; margin: auto }
input, textarea { background: transparent; }
fieldset {
  min-inline-size: initial;
  border: 1px solid #ff9933;
  border-top: none;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  margin-top: 50px;
  margin-bottom: 15px;
  background: #f5f5f5;
  color: #003399;
  padding: 8px 15px;
}

fieldset legend {
  border-radius: 15px 0 0 0;
  padding: 3px;
  height: 24px;
  padding-left: 15px;
  box-sizing: border-box;
  background-color: #6699ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: -1px;
  top: -24px;
  left: -1px;
  position: absolute;
  color: white;
  width: unset;
}

button{
  border: 1px solid #504E63;
  padding: 1px 3px;
  background-color: #DEDDFC;
  letter-spacing: 5px;
  text-align: center;
  padding-left: 8px;
}

textarea {
  resize: vertical;
  overflow-wrap: anywhere;
}